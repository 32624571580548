import React from 'react';
import '../styles/blog.css';
import Image from '../images/line-44.png';
import { useForm, ValidationError } from '@formspree/react';
function ContactForm() {
    const [state, handleSubmit] = useForm("myyoedrr");
    if (state.succeeded) {
        return <p>Thanks for joining!</p>;
    }
    return (
        <form onSubmit={handleSubmit}>
        <label htmlFor="email">
          Email Address
        </label>
        <input
          id="email"
          type="email" 
          name="email"
          placeholder='Email'
        />
        <ValidationError 
          prefix="Email" 
          field="email"
          errors={state.errors}
        />
        <textarea
          id="message"
          name="message"
          placeholder='Message'
        />
        <ValidationError 
          prefix="Message" 
          field="message"
          errors={state.errors}
        />
        <button type="submit" disabled={state.submitting}>
          Submit
        </button>
      </form>
    );
  }
export class Post extends React.Component{
   
    render(){
        return (
        <div className='blog-single'>
            
            <h1>How to Add Infinite Scrolling to Collection Pages - Dawn - Shopify</h1>
            <p>This tutorial is for Shopify&apos;s Dawn theme only.</p>
            <h3>Step: 1</h3>
            <p>We&apos;re going to use a script called Scrollify which &apos;Ajaxinates&apos; Shopify.</p><p> First download the script <a href="https://minionmade.github.io/scrollify/" rel="noreferrer" target="_blank">here.</a> Add ajaxinate.min.js to the assets folder of your shopify theme</p>
            <h3>Step: 2</h3>
            <p>Then add this script tag in main-collection-product-grid.liquid near the other asset_url tags:</p>
            <pre>
              <code>

                &#123;&#37; &#39;ajaxinate.min.js&#39; | asset_url | script_tag  &#37;&#125;<br></br>
            </code>
            </pre>
            <h3>Step: 3</h3>
            <p>Add this script right before the schema starts in main-collection-product-grid.liquid to create a new ajaxinate instance.</p>
            <pre><code>
              &lt;script&gt;<br></br>
              const endlessCollection = new Ajaxinate(&#123;<br></br>
              container: &#39;#product-grid&#39;,<br></br>
              pagination: &#39;.infinite_next&#39;,<br></br>
              &#125;);<br></br>
              &lt;/script&gt;<br></br>
            </code></pre>

            <h3>Step: 4</h3> 
           <p>Then in snippets find pagination.liquid and edit line 44</p>

<p>Add </p>
<pre><code>class=&quot;infinite_next&quot;</code></pre>

<p>To the <code>&#60;li&#62;</code> under “paginate.next”. It should look like this screenshot:</p>
            <img className='image' src={Image}/>
            <h3>Step: 5</h3>
            <p>We now need to add another initialization in facets.js to account for when a user is filtering products and the pagination changes. In facets.js add this script to the static renderProductCount() function on line 85. <br></br><br></br>We don&apos;t need script tags this time because we&apos;re placing it in a javascript function.</p>
            <pre><code>
              const endlessCollection = new Ajaxinate(&#123;<br></br>
              container: &#39;#product-grid&#39;,<br></br>
              pagination: &#39;.infinite_next&#39;,<br></br>
              &#125;);<br></br>

            </code></pre>
           <h3>All Done! - Preview your store and view a collection page.</h3>

           <p>If you like to hire us to do this for your Shopify, please contact us!</p>
           <ContactForm />
           
        </div>
        );
    }
}