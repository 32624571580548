// Map number x from range [a, b] to [c, d]
const map = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c;
// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;
const calcWinsize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  };
};
// Gets the mouse position
const getMousePos = e => {
  return {
    x: e.clientX,
    y: e.clientY
  };
};
const wrapElements = (elems, wrapType, wrapClass) => {
  elems.forEach(char => {
    // add a wrap for every char (overflow hidden)
    const wrapEl = document.createElement(wrapType);
    wrapEl.classList = wrapClass;
    char.parentNode.appendChild(wrapEl);
    wrapEl.appendChild(char);
  });
};


export {
  map,
  lerp,
  calcWinsize,
  getMousePos,
  wrapElements
};