
import React from 'react';
import ReactDOM from 'react-dom';
import {Cursor} from './components/cursor';
import {Navbar} from './components/navbar.js';
import {Home} from './components/home';
import {Blog} from './components/blog';
import {Post} from "./components/post";
import { AnimatePresence } from 'framer-motion';
import {
    HashRouter,
    Routes,
  Route
  } from "react-router-dom";
// ReactDOM.render(
//     <Navbar/>,
//     document.getElementById('navigation')
// );

ReactDOM.render(
    <Cursor/>,
    document.getElementById('cursor')
);
ReactDOM.render(
    <HashRouter>
    <Navbar/>
    <AnimatePresence>
    <Routes>
        <Route path="/" element={<Home />} />
         <Route path="blog" element={<Blog />} />
        <Route path="blog/how-to-add-infinite-scrolling-to-dawn" element={<Post />} />
    </Routes>
    </AnimatePresence>
    
  </HashRouter>,
  document.getElementById('undefined')
);
import CursorScript from "./scripts/cursor.js";
const cursor = new CursorScript(document.querySelector(".cursor"));
[...document.querySelectorAll("a, .menu span")].forEach((el) => {
  el.addEventListener("mouseenter", () => cursor.emit("enter"));
  el.addEventListener("mouseleave", () => cursor.emit("leave"));
});

