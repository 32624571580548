import React from "react";
import imagesLoaded from 'imagesloaded';
import '../styles/blog.css';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
imagesLoaded(document.querySelectorAll('img'), { background: true }, () => document.body.classList.remove('loading'));
export class Blog extends React.Component{
    render(){
        return (
            <div>
                <motion.div initial={{ y: 1000 }}
                    animate={{ y: -2000 }}

                    transition={{ duration: 1 }}> <div className="block_slide"></div></motion.div>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}

                    transition={{
                        delay: 1,
                        x: { type: "spring", stiffness: 100 },
                        default: { duration: 2 },
                    }}
                >
            <section className="blog__container">
            <h2>BLOG </h2>
            <div className="article__grid">
                <div className="article"><Link to={`/blog/how-to-add-infinite-scrolling-to-dawn`}><h3>How to add Infinite Scrolling to Dawn collection pages - Shopify</h3></Link></div>
                
            </div>
            
            </section>
                </motion.div>
            </div>

        );
    }
}