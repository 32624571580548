import React from "react";
import Dualforces from '../images/laptop-screen-mockup.jpg';

import Daydreamer from '../images/daydreamer.jpg';

import DiffusionMasters from '../images/diffusion-masters-mock.jpg';



import GSAP from "gsap";
import { Linear, Expo } from "gsap";
import '../styles/shaders-on-scroll.sass';
import imagesLoaded from 'imagesloaded';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { Draggable } from 'gsap/all';
import DrawSVGPlugin from '../scripts/DrawSVGPlugin.js';
import SplitText from "../scripts/SplitText.js";
import Animations from "../scripts/Animations";
import { useForm, ValidationError } from '@formspree/react';
function ContactForm() {
  const [state, handleSubmit] = useForm("mleaworz");
  if (state.succeeded) {
      return <p>Thanks for joining!</p>;
  }
  return (
      <form onSubmit={handleSubmit}>
        <label htmlFor="full-name">Full Name</label>
      <input type="text" name="name" id="full-name" placeholder="First and Last" required="" />
      <label htmlFor="email">
        Email Address
      </label>
      <input
        id="email"
        type="email" 
        name="email"
        placeholder="E-mail"
      />
      <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
       <label htmlFor="budget">Project Budget</label>
      <input name="budget" type="text" id="budget" placeholder="Project Budget" />
      <textarea
        id="message"
        name="message"
        placeholder="Project Description - Tell us everything you know"
      />
      <ValidationError 
        prefix="Message" 
        field="message"
        errors={state.errors}
      />
          <input type="hidden" name="_subject" id="email-subject" value="Dawn Infinite Collections" />
      <button type="submit" disabled={state.submitting}>
        Submit
      </button>
    </form>
  );
}
export class Home extends React.Component{
    render(){
        return (
                <div>
                    <div className="content">
                    <div className="scroll__stage">
                        <div className="scroll__content">
                        <section className="section start">
                            
                            <div className="flex">
                            <div className="section__title">
                                
                                <h2 className="section__title-text">
                                Boutique design, development and experience agency.
                                </h2>
                                <p className="section__title-arrow">
                                <span>➤</span><br />
                                <span>➤</span>
                                </p>
                                <span id="la_time_1"></span>
                            </div>
                            <p className="section__paragraph">
                                We serve highly ambitious brands that want to elevate their visual
                                content, branding and online experience.
                                
                            </p>
                            </div>
                        </section>
                        <section className="section" id="about">
                            <div className="flex">
                            <header>
                                <h1>About</h1>
                                <div className="small__divider"></div>
                                <h3>Undefined</h3>
                                <h3 className="margin-left">Agency</h3>
                            </header>
                            <div className="text">
                                <p>
                                Undefined Agency is an independent branding and digital design
                                studio based in Los Angeles, CA. We are designers who think in
                                code. We believe that every project is unique and should be
                                tailor-made with commitment and precision.
                                </p>
                                <p>
                          Undefined aligns with forward thinking brands to innovate,
                                adapt and grow. Through a seamless fusion of art + code, we
                                help our clients businesses evolve and thrive.
                                </p>
                                <h4>Creative Director</h4>
                                <p className="p-0 dg">[ Dylan Glover ]</p>
                            </div>
                            </div>
                        </section>
                        <section className="section black">
                            <div className="flex">
                            <div className="section__title">
                                <h1 className="section__title-number">01</h1>
                                <h2 className="section__title-text animate__fade_in">
                                &quot; Design with the mind in mind &quot;
                                </h2>
                            </div>
                            <p className="section__paragraph">
                        Creating innovative, future forward work.<br />
                                
                            </p>
                            </div>
                        </section>
                        <section id="services" className="section">
                            <div className="marquee">
                            <div className="marquee__inner" aria-hidden="true">
                                <div className="marquee__part">what we do - (our services) design + development</div>
                                <div className="marquee__part">what we do - (our services) design + development</div>
                            </div>
                            </div>

                            <div className="info__container flex">
                            <div className="block">
                                <h2>Design</h2>
                                <div className="small__divider"></div>
                                <ul>
                                <li>Website & E-commerce</li>
                                <li>Art Direction</li>
                                <li>Brand Identity</li>
                                <li>User Experience</li>
                                <li>User Interface</li>
                                <li>Prototyping & Wireframe</li>
                                <li>Print & Product Design</li>
                                <li>Event Production</li>
                                </ul>
                            </div>
                            <div className="block">
                                <h2>Tech</h2>
                                <div className="small__divider"></div>
                                <ul>
                                <li>Shopify Plus</li>
                                <li>SEO</li>
                                <li>Wordpress</li>
                                <li>Custom Web Development</li>
                                <li>APIs</li>
                                <li>Integration Implementation</li>
                                <li>Cinema 4D and SketchUp</li>
                                <li>Photoshop, Illustrator, After Effects</li>
                                <li>Resolume Arena</li>
                                </ul>
                                <div className="other__services">
                                <svg id="astrix" className="s-asterisk" x="0px" y="0px" width="36.1px" height="40px" viewBox="0 0 36.1 40"
                                    xmlSpace="preserve">
                                    <g className="l-s-asterisk op">
                                    <line x1="18.1" y1="0" x2="18.1" y2="40"></line>
                                    <line x1="0.8" y1="10" x2="35.4" y2="30"></line>
                                    <line x1="0.8" y1="30" x2="35.4" y2="10"></line>
                                    <line x1="18.1" y1="40" x2="18.1" y2="0"></line>
                                    </g>
                                    <g className="d-s-asterisk op">
                                    <line x1="18.1" y1="0" x2="18.1" y2="40"></line>
                                    <line x1="0.8" y1="10" x2="35.4" y2="30"></line>
                                    <line x1="0.8" y1="30" x2="35.4" y2="10"></line>
                                    <line x1="18.1" y1="40" x2="18.1" y2="0"></line>
                                    </g>
                                </svg>

                                <div className="other__servives__right">
                                    <p>Other creative services</p>
                            <a href="#contact">
                              <h2 className="white anchor" href="#contact">(CONTACT US)</h2>
                            </a>
                                </div>
                                </div>
                            </div>
                            </div>
                        </section>
                        <section id="work" className="section">
                            <div className="marquee">
                            <div className="marquee__inner" aria-hidden="true" >
                                <div className="marquee__part">We redesign, rebrand, renew (your brand)</div>
                                <div className="marquee__part">We redesign, rebrand, renew (your brand)</div>

                            </div>
                            </div>
                            <header>
                            <h2 className="work animate__fade_in">Work</h2>
                            <h3>Some notable examples</h3>
                            

                            </header>


                        </section>
                        <section id="gallery" className="section">
                            <div className="section__work">
                            <div className="col-one-third" id="one_e">
                                <a target="_blank" rel="noreferrer" className="modal" href="https://dualforces.com">
                          <img className="portfolio_item intro__img" id="Dualforces_img" src={Dualforces} data-value="asset1"
                                    alt="Client DualForces, What we did: Custom Shopify Development - Full site" />

                                </a>
                            </div>
                            <div className="col-one-third" id="two_e">
                                <a target="_blank" rel="noreferrer" className="modal" href="https://daydreamerla.com">
                                <img className="portfolio_item" id="Daydreamer_img" src={Daydreamer} data-value="asset2"
                                    alt="Client: Daydreamer LA, What we did: Shopify Plus - Customer accounts, Check out experience, responsive development" />
                                </a>

                            </div>
                            <div className="col-one-third" id="three_e">
                                <a target="_blank" rel="noreferrer" className="modal"  href="https://www.diffusionmasters.co.uk">
                                <img className="portfolio_item" id="diffusion_masters_img" src={DiffusionMasters} data-value="asset3"
                                    alt="Client: Diffusion Masters, What we did: Branding, Logo Design" />

                                </a>
                            </div>
                            </div>
                        </section>
                        <section id="contact" className="section">
                            <div className="flex">

                            <div className="flex">
                                <h1 className="title">
                                Got something on your mind? <br />
                                <span className="yellow">→</span>Let&apos;s Talk.
                                </h1>

                                <div className="half">
                                <div className="contact_info">
                                    <div className=" flex">
                                    <span id="la_time"></span>
                                    <div>
                                        <p id="open"></p>
                                    </div>
                                    </div>
                                    <h2>General & Work Inquiries:</h2>
                                    <a className="fs-1" href="mailto:info@undefined.agency">
                                    <h3>info@undefined.agency</h3>
                                    </a>
                                  
                                </div>
                                <ContactForm />
                               
                                </div>
                            </div>
                            </div>
                        </section>
                        </div>
                        <footer id="footer" className="section">
                        <div className="footer__links">
                            <a href="#">Get in touch</a>
                            <div className="spacer"></div>
                            <div className="spacer"></div>
                            <a href="https://instagram.com/undefinedagency" target="_blank" rel="noreferrer">Instagram</a>
                            <a href="https://facebook.com/undefinedagencyla" target="_blank" rel="noreferrer">Facebook</a>
                            <a href="#">Made with hands by:</a>
                        </div>
                        <div className="footer_UA">
                            <div id="quote">UNDEFINED</div>
                        </div>
                        </footer>
                    </div>
                    <progress max="100" value="0"></progress>
                    </div>
                    
                    <div className="proxy"></div>
                    
                    <div className="modal__content">
                    <button className="content__back" aria-label="Back to main view"> 
                        <svg id="back-btn" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 329.09 329.09">
                        <title>Asset 3</title>
                        <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                            <polygon
                                points="262.98 74.78 254.31 66.11 164.55 155.88 74.78 66.11 66.11 74.78 155.88 164.55 66.11 254.31 74.78 262.98 164.55 173.22 254.31 262.98 262.98 254.31 173.22 164.55 262.98 74.78"
                                className="polyfill_white" />
                            <circle cx="164.55" cy="164.55" r="159.05"
                                className="circleStyles" />
                            </g>
                        </g>
                        </svg>
                        </button>
                    <div className="reel">
                        <img id="asset__image" className="reel__img" alt="Some image" />
                    </div>

                    <div className="content--flex content--second">
                        <div className="half">

                        <h1 id="asset__number"></h1>
                        <h3 id="asset__title"></h3>
                        <p id="asset__description"></p>
                        </div>
                        <div className="half">
                        <a target="_blank" id="asset__link">View Project ↗</a>
                        </div>
                    </div>
                    </div>
                    

                
                    <svg id="scrollIndicator" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve">
                    <defs>
                    
                        <path id="circlePath" d=" M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "/>
                    </defs>
                    <circle cx="150" cy="100" r="75" fill="none"/>
                    
                    <g>
                        <use xlinkHref="#circlePath" fill="none"/>
                        <text>
                            <textPath xlinkHref="#circlePath">Scroll down or drag to navigate</textPath>
                        </text>
                        
                    </g>
                </svg>
                <span id="arrowscroller">&gt;</span>
                </div>
        );
    }


    componentDidMount(){
        

//import $ from 'jquery';
//cursor 
// import Cursor from "./cursor.js";
// const cursor = new Cursor(document.querySelector(".cursor"));
// [...document.querySelectorAll("a, .menu span")].forEach((el) => {
//   el.addEventListener("mouseenter", () => cursor.emit("enter"));
//   el.addEventListener("mouseleave", () => cursor.emit("leave"));
// });




//load images




// //loading



imagesLoaded(document.querySelectorAll('img'), { background: true }, () => document.body.classList.remove('loading'));


//astrix
var astrix = document.getElementById("astrix");
GSAP.to(astrix, {
  duration: 3,
  rotation: "360",
  ease: Linear.easeNone,
  repeat: -1,
});




// SERVICES MARQUEE

let isScrollingDown = true;
let tween = GSAP.to(".marquee__part", {
  xPercent: -100,
  repeat: -1,
  duration: 20,
  ease: "linear",
}).totalProgress(0.2);
GSAP.set(".marquee__inner", {
  xPercent: -50,

});

GSAP.to(tween, {
  timeScale: isScrollingDown ? 1 : -1,
});


// Add Los Angeles Clock to Home Page


function displayTime() {
  var currentTime = new Date();

  // Los Angeles is UTC-8, but we need to account for DST
  var utcHours = currentTime.getUTCHours();
  var utcMinutes = currentTime.getUTCMinutes();
  var utcSeconds = currentTime.getUTCSeconds();

  // Determine if daylight saving time is in effect
  var isDST = currentTime.getTimezoneOffset() < new Date(currentTime.getFullYear(), 0, 1).getTimezoneOffset();

  // Adjust hours for Los Angeles time (UTC-8)
  var hours = isDST ? utcHours - 7 : utcHours - 8;
  var minutes = utcMinutes;
  var seconds = utcSeconds;

  // Correct for negative hours
  if (hours < 0) {
    hours += 24;
  }

  // Format AM/PM
  var meridiem = "AM";
  if (hours >= 12) {
    meridiem = "PM";
    hours = hours % 12;
  }
  if (hours === 0) {
    hours = 12;
  }

  // Add leading zeros
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }

  // Update the clock in the HTML
  var clockDiv = document.getElementById("la_time_1");
  if (clockDiv != null) {
    clockDiv.innerText =
      "Los Angeles, USA " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds +
      " " +
      meridiem;
  } else {
    return;
  }
}

displayTime();
setInterval(displayTime, 500);

//scroll stage


GSAP.registerPlugin(Draggable, ScrollToPlugin, ScrollTrigger, SplitText, DrawSVGPlugin);


//scroll indicator


 GSAP.to("#scrollIndicator", {rotation: 1440, duration: 60, repeat: -1});
GSAP.to("#scrollIndicator", {
    scrollTrigger: {
        trigger: ".scroll__stage",
        scrub: 1,
        start: 'center center',
        end: '+=13000',
        
    },
    rotation: 3600,
    duration: 10,
    ease: "none",
});

// if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
//  console.log("fuck yeah");
// }else{console.log("fuck no");}
/* Main navigation */
let panelsContainer = document.querySelector(".scroll__content"), tweener;
console.log(window.location.pathname);
if(window.location.hash === '/#work'){
console.log('blog page');
}else if (window.location.pathname === '/'){
document.querySelectorAll(".anchor").forEach(anchor => {
  anchor.addEventListener("click", function (e) {
    e.preventDefault();
    const originalString = e.target.getAttribute("href");
    console.log("Old: " + originalString);
    const newString = originalString.replace('/', '');
    const stripHash = newString.substring(1);
    console.log("New: " + stripHash);
    let targetElem = document.querySelector(stripHash),
      y = targetElem;
    console.log(y);
    if ( y == null){
      
      window.location.assign('/'+newString);
      
    }
    if (targetElem && panelsContainer.isSameNode(targetElem.parentElement)) {
      let totalScroll = tweener.scrollTrigger.end - tweener.scrollTrigger.start,
        totalMovement = (panels.length - 1) * targetElem.offsetWidth;
      y = Math.round(tweener.scrollTrigger.start + (targetElem.offsetLeft / totalMovement) * totalScroll);
    }
    GSAP.to(window, {
      scrollTo: {
        y: y,
        autoKill: false,
      },
      duration: 1
    });
  });
});
}



/* Panels */
const panels = GSAP.utils.toArray(".scroll__content .section");
tweener = GSAP.to(panels, {
  xPercent: -100 * (panels.length - 1),
  ease: "none",
  scrollTrigger: {
    trigger: ".scroll__content",
    pin: true,
    start: "top top",
    scrub: 1,
    overflow: 'hidden',
    end: () => "+=" + (panelsContainer.offsetWidth - innerWidth)
  }
});
let scrollContainer = document.querySelector(".scroll__content");

// total scroll amount divided by the total distance that the sections move gives us the ratio we can apply to the pointer movement so that it fits. 
var dragRatio = scrollContainer.offsetWidth / (window.innerWidth * (panels.length - 1));
Draggable.create(".proxy", {
  trigger: scrollContainer,
  type: "x",
  onPress() {
    this.startScroll = tweener.scrollTrigger.scroll();
  },
  onDrag() {
    tweener.scrollTrigger.scroll(this.startScroll - (this.x - this.startX) * dragRatio);
    // if you don't want it to lag at all while dragging (due to the 1-second scrub), uncomment the next line:
    //horizontalScroll.getTween().progress(1);
  }
})[0];

 

//progress
GSAP.to('progress', {
  value: 100,
  ease: 'none',
  scrollTrigger: {
    scrub: 0.3,
  }
});
//animations


new Animations(document.querySelector('.content'));


GSAP.to(".work.animate__fade_in", {
  opacity: 1,
  y: 150,
  duration: .5,
  ease: "ease-in",
  scrollTrigger: {
    //markers: "true",
    trigger: ".work",
    containerAnimation: tweener,
    start: "left center",
    toggleActions: "play none none reverse"
  }
});

//on scroll animations


// const staggerMe = GSAP.utils.toArray(".animate__fade_in");
// ScrollTrigger.addEventListener("refresh", animateNow);

// const iObserver2 = new IntersectionObserver(items => {
//   // console.log(items);
//   if (items[0].isIntersecting) {
//     //console.log('Visible');
//     animateNow();
//   } else {
//     return;
//     //console.log('Not visible');
//   }
// });

// for (let i = 0; i < staggerMe.length; i++) {
//   iObserver2.observe(staggerMe[i]);
// }
// animateNow();
// function animateNow() {

//   staggerMe.forEach(staggerMe => {

//     // Reset if needed
//     if (staggerMe.anim) {
//       staggerMe.anim.progress(1).kill();
//       staggerMe.split.revert();
//     }
//     staggerMe.split = new SplitText(staggerMe, {
//       type: "lines,words,chars",
//       linesClass: "split-line"
//     });
//     staggerMe.anim = GSAP.fromTo(staggerMe.split.chars, {
//       delay: .5,
//       scrollTrigger: {
//         trigger: '.animate__fade_in',
//         toggleActions: "restart pause resume reverse",
//         start: "top 100%",
//       },

//       duration: 0.4,
//       ease: "circ.out",
//       y: -300,
//       stagger: 0.02,
//     });
//   });
// }







// /*
//  * FOOTER ANIMATION pop-in
//  *
//  */
const quotes = document.querySelectorAll("#quote");
ScrollTrigger.addEventListener("refresh", setupSplits);
const iObserver = new IntersectionObserver(items => {
  if (items[0].isIntersecting) {
    //console.log('Visible');
    setupSplits();
  } else {
    //console.log('Not visible');
  }
});
iObserver.observe(document.getElementById('quote'));

function setupSplits() {
  quotes.forEach(quote => {
    // Reset if needed
    if (quote.anim) {
      quote.anim.progress(1).kill();
      quote.split.revert();
    }
    quote.split = new SplitText(quote, {
      type: "lines,words,chars",
      linesClass: "split-line"
    });
    // Set up the anim
    quote.anim = GSAP.from(quote.split.chars, {
      scrollTrigger: {
        trigger: '#footer',
        toggleActions: "restart pause resume reverse",
        start: "top 200%",
      },
      duration: 0.6,
      ease: "circ.out",
      y: 600,
      stagger: 0.1,
    });
  });
}



function displayTimeLA() {
  var currentTime = new Date();
  var timeDifference = currentTime.getTimezoneOffset() / 60;
  var hours = currentTime.getHours() + timeDifference;
  var minutes = currentTime.getMinutes();
  var seconds = currentTime.getSeconds();
  hours = hours - 8;
  if (hours > 24) {
    hours = hours - 24;
  }
  if (hours < 0) {
    hours = hours + 24;
  }
  var meridiem = "AM";
  if (hours >= 12) {
    hours = hours - 12;
    meridiem = "PM";
  }
  if (hours === 0) {
    hours = 12;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (hours < 10) {
    hours;
  }
  var weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";
  var day = currentTime.getDay();
  var n = weekday[day];
  var clockDiv = document.getElementById("la_time");
  //console.log(clockDiv);
  if (clockDiv != null) {
    clockDiv.innerText =
      "It's " + n + " " +
      hours +
      ":" +
      minutes +
      " " +
      meridiem +
      " in Los Angeles";
  }

  // if (document.getElementById("open") != null) {
  //   if ((day >= 1) && (day <= 5)) {

  //     if (
  //       ((hours >= 9) && (hours <= 11) && (meridiem == "AM")) ||
  //       ((hours >= 1) && (hours <= 5) && (meridiem == "PM")) ||
  //       ((hours == 12) && (meridiem = "PM"))
  //     ) {
       
  //       document.getElementById("open").innerHTML = "We're in the office!";
  //       document.getElementById("phone").style.display = "none";
  //     } else {
  //       document.getElementById("phone").style.display = "none";
  //       document.getElementById("open").innerHTML =
  //         "Sorry, we're out of the office right now, but send us a message and we'll get back to you as soon as possible. (Generally within 24-hours)";
  //     }
  //   } else {
  //     document.getElementById("phone").style.display = "none";
  //     document.getElementById("open").innerHTML =
  //       "Sorry, we're out of the office right now, but send us a message and we'll get back to you as soon as possible. (Generally within 24-hours)";
  //   }
  // } else {
  //   return;
  // }
}
displayTimeLA();

setInterval(displayTimeLA, 1000);




// Content elements
const content = {
  first: document.querySelector('.content'),
  second: document.querySelector('.modal__content'),
  nav: document.querySelector('#menu')
};

// First page's content.
const firstPageContent = {
  img: content.first.querySelectorAll('.portfolio_item'),
  title: content.first.querySelector('.intro__title'),
  enter: content.first.querySelectorAll('.modal')
};



// Second page's content.
const secondPageContent = {
  reel: content.second.querySelector('.reel'),
  backCtrl: content.second.querySelector('.content__back')
};


// Revealer element

const workData = {
  asset1: {
    number: "001",
    img: {Dualforces},
    title: 'Dualforces',
    desc: 'Custom Shopify Development - Full site',
    url: 'https://dualforces.com'
  },
  asset2: {
    number: "002",
    img: {Daydreamer},
    title: 'Daydreamer',
    desc: 'Shopify Plus - Customer accounts, Check out experience, responsive development',
    url: 'https://daydreamerla.com'
  },
  asset3: {
    number: "003",
    img: {DiffusionMasters},
    title: 'Diffusion Masters',
    desc: 'Branding and Logo Design',
    url: 'https://www.diffusionmasters.co.uk'
  }
};
console.log(workData.asset3.img.DiffusionMasters);
const pageToggleTimeline = GSAP.timeline();
// Animate things: show revealer animation, animate first page elements out (optional) and animate second page elements in (optional)
const showNextPage = (e) => {
  e.preventDefault();
  console.log(e);
  const targetImg = e.target.id;
  const ease = Expo.easeInOut;
  const duration = 1.5;
  //console.log(targetImg);
  // var transit = document.querySelector('#' + targetImg);
  // console.log(transit.closest('.col-one-third'));
  // const parentCol = transit.closest('.col-one-third').id;
  GSAP.set("#back-btn", {duration: 2, drawSVG: 0}, 0.1);
  pageToggleTimeline
    .to('#' + targetImg, {
      ease: ease,
      duration: duration,

      opacity: '1',

    }, "+= 2")
    .to('.col-one-third', {
      ease: ease,
      duration: duration,
      y: '-150%',
      opacity: '0',

    }, "+= 2")
    .to(content.nav, {
      ease: ease,
      duration: duration,
      y: '-100%',
      opacity: '0',

    }, "+= 2")

    .to(content.second, {
      ease: ease,
      duration: duration,
      y: '-100%'
    }, "+= 2")
    
    .to("#back-btn", {
      ease: ease,
      duration: duration,
      rotation: '-360',
      opacity: '1'
    }, "+= 2");

  if (pageToggleTimeline.reversed()) {
    pageToggleTimeline.play();
  }


  const asset = e.target.dataset.value;

  console.log(workData[asset]);
  const titleRender = workData[asset].title.replace(/ /g, "");
  document.querySelector('#asset__image').src = workData[asset].img[titleRender];
  document.querySelector('#asset__title').innerHTML = workData[asset].title;
  document.querySelector('#asset__number').innerHTML = workData[asset].number;
  document.querySelector('#asset__description').innerHTML = workData[asset].desc;
  document.querySelector('#asset__link').href = workData[asset].url;

  //panelTimeline.pause();
};
for (let z = 0; z < firstPageContent.enter.length; z++) {
  firstPageContent.enter[z].addEventListener('click', showNextPage);
}

// Animate back
const showIntro = () => {
  pageToggleTimeline.reverse();
  //panelTimeline.play();
  

};
secondPageContent.backCtrl.addEventListener('click', showIntro);

    }
    componentWillUnmount(){
      return;
    }
}


