import React from "react";
import { Link } from "react-router-dom";

import logo from '../images/UA-logo.svg';

export class Navbar extends React.Component{
    render(){
        return (
            <nav id="menu" role="navigation">
                <Link to="/"><img src={logo} alt="Logo" /></Link>
                <ul className="menu">
                <li><Link id="about_link" className="anchor" to="/#about">About</Link></li>
                <li><Link id="work_link" className="anchor" to="/#work">Work</Link></li>
                <li><Link id="contact_link" className="anchor" to="/#contact">Contact</Link></li>
                <li><Link to="/blog">Blog</Link></li>
                </ul>
            </nav>
        );
    }
}